import { PayoutMethod } from '../types/Payout'
import api from './api'

const payoutMethodsService = {
  async getPayoutMethods(): Promise<PayoutMethod[]> {
    const { data } = await api.get('/v1/payout-methods')
    return data
  },
  async getAvailableIbanCountries(): Promise<string[]> {
    const { data } = await api.get(
      '/v1/payout-methods/available-iban-countries',
    )
    return data
  },
}

export default payoutMethodsService
