import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  BillingAddress,
  getBillingAddressesResponse,
  NextPayout,
  PayoutHistory,
  SheltersNft,
  User,
  UserPreferences,
  UserWallet,
} from '../types/User'
import constants from '../config/constants'
import UserService from '../services/user.service'
import {
  setBillingAddresses,
  setNfts,
  setPayoutMethods,
  setUser,
  setUserPayoutMethods,
  setUserPreferences,
  setWallet,
  setPayoutsHistory,
  useDispatch,
  setYields,
  updateUserInfoFlowStatus,
} from '../store'
import {
  getUserPayoutMethodsResponse,
  PayoutMethod,
  UserPayoutMethod,
} from '../types/Payout'
import payoutMethodsService from '../services/payoutMethods.service'
import PropertyService from '../services/property.service'
import useCurrency from '../hooks/useCurrency'
import { useRouter } from 'next/router'
import { GoogleTagManagerService } from '../services/google-tag-manager.service'
import { VeriffDecisionVerificationStatus } from '../types/Veriff'
import useLocale from '../hooks/useLocale'
import Bugsnag from '@bugsnag/js'
import { useUnleashContext } from '@unleash/nextjs/client'

export interface AuthContextProps {
  login: (email: string, password: string) => Promise<void>
  authWithGoogle: () => Promise<void>
  authWithGoogleCallback: (register: boolean) => Promise<void>
  logout: () => Promise<void>
  isLoggedIn: boolean
  user: any
  setUser: (user: User | null) => void
  isLoading: boolean
}

const authContext = createContext<AuthContextProps>(undefined as any)

const AuthProvider = ({ children }: { children: any }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [user, _setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { currency } = useCurrency()
  const { locale } = useLocale()
  const router = useRouter()
  const updateUnleashContext = useUnleashContext()

  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (window.localStorage.getItem(constants.IS_LOGGED_IN)) {
      setIsLoggedIn(true)
      initUser().then(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [])

  const initUser = async (): Promise<void> => {
    try {
      const [user, userPreferences, userWallet] = await Promise.all([
        loadUser(),
        loadUserPreferences(),
        loadUserWallet(),
        loadUserBillingAddresses(),
        loadUserPayoutsHistory(),
        loadPayoutMethods(),
      ])

      await updateUnleashContext({ userId: user.id }).catch()

      await loadUserPayoutMethods(userPreferences)

      if (!userWallet) return
      const userNfts = await loadUserNfts(userWallet.address)

      if (!userNfts) return
      const userNextPayouts = await loadNextPayouts(userNfts)
    } catch (err) {
      // todo: display error
    }
  }

  const login = async (email: string, password: string): Promise<void> => {
    try {
      setIsLoading(true)
      await UserService.loginWithEmail(email, password)
      window.localStorage.setItem(constants.IS_LOGGED_IN, 'true')
      setIsLoggedIn(true)
      GoogleTagManagerService.Login({ method: 'password' })
      await initUser()
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  const authWithGoogle = async () => {
    const googleAuthApiUrl = `${process.env.NEXT_PUBLIC_API_BASEURL}/v1/auth/google`
    router.push(googleAuthApiUrl)
  }

  const authWithGoogleCallback = async (register: boolean) => {
    try {
      const user = await UserService.getUser()

      if (user) {
        window.localStorage.setItem(constants.IS_LOGGED_IN, 'true')
        setIsLoggedIn(true)
        setIsLoading(true)
        _setUser(user)
        dispatch(setUser(user))
        window.localStorage.setItem(constants.IS_LOGGED_IN, 'true')
        setIsLoggedIn(true)
        if (register) {
          GoogleTagManagerService.Signup({ method: 'google' })
        } else {
          GoogleTagManagerService.Login({ method: 'google' })
        }
        await initUser()
      } else {
        throw new Error('User not found')
      }
    } catch (err) {
      console.error('Error during Google authentication callback', err)
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  const logout = async (): Promise<void> => {
    try {
      setIsLoading(true)
      await UserService.logout()
    } finally {
      window.localStorage.removeItem(constants.IS_LOGGED_IN)
      setIsLoggedIn(false)
      _setUser(null)
      setIsLoading(false)
      setTimeout(() => window.location.reload(), 100)
    }
  }

  const loadUser = async (): Promise<User> => {
    const user = await UserService.getUser()
    _setUser(user)
    dispatch(setUser(user))

    if (
      user &&
      user.verification &&
      user.verification.status === VeriffDecisionVerificationStatus.APPROVED
    ) {
      dispatch(updateUserInfoFlowStatus({ userInfo: true, kyc: true }))
    } else if (user && user.firstname && user.lastname && user.nationality) {
      dispatch(updateUserInfoFlowStatus({ userInfo: true }))
    }

    GoogleTagManagerService.Identify({ userId: user.id, email: user.email })

    Bugsnag.setUser(user.id, user.email, user?.firstname + ' ' + user?.lastname)

    return user
  }

  const loadUserPreferences = async (): Promise<UserPreferences> => {
    const userPreferences = await UserService.getUserPreferences()
    dispatch(setUserPreferences(userPreferences))
    if (userPreferences.lang !== locale) {
      setTimeout(() => {
        router.push(router.asPath, router.asPath, {
          locale: userPreferences.lang,
        })
      }, 200)
    }
    return userPreferences
  }

  const loadUserWallet = async (): Promise<UserWallet | null> => {
    const wallets = await UserService.getWallets() // todo: instead use getWallet
    if (wallets.length) {
      dispatch(setWallet(wallets[0]))
      dispatch(updateUserInfoFlowStatus({ wallet: true }))
      return wallets[0]
    } else {
      return null
    }
  }

  const loadUserPayoutsHistory = async (): Promise<PayoutHistory[]> => {
    const userPayoutsHistory = await UserService.getPayoutsHistory(currency, 6)
    dispatch(setPayoutsHistory(userPayoutsHistory.payoutsHistory))
    return userPayoutsHistory.payoutsHistory
  }

  const loadUserBillingAddresses = async (): Promise<BillingAddress[]> => {
    const userBillingAddressesResponse: getBillingAddressesResponse =
      await UserService.getBillingAddresses()
    dispatch(setBillingAddresses(userBillingAddressesResponse.billingAddresses))
    if (userBillingAddressesResponse.billingAddresses.length) {
      dispatch(updateUserInfoFlowStatus({ billingAddress: true }))
    }
    return userBillingAddressesResponse.billingAddresses
  }

  const loadUserPayoutMethods = async (
    userPreferences: UserPreferences,
  ): Promise<UserPayoutMethod[]> => {
    const userPayoutMethodsResponse: getUserPayoutMethodsResponse =
      await UserService.getUserPayoutMethods()

    const pm = userPayoutMethodsResponse.userPayoutMethods.map(
      (userPayoutMethod) => {
        const isCurrent =
          userPayoutMethod.id === userPreferences.userPayoutMethodId
        const isNext =
          userPayoutMethod.id === userPreferences.nextUserPayoutMethodId

        return {
          ...userPayoutMethod,
          isCurrent,
          isNext,
        }
      },
    ) as UserPayoutMethod[]
    dispatch(setUserPayoutMethods(pm))
    return pm
  }

  const loadPayoutMethods = async (): Promise<PayoutMethod[]> => {
    const payoutMethods: PayoutMethod[] =
      await payoutMethodsService.getPayoutMethods()
    dispatch(setPayoutMethods(payoutMethods))
    return payoutMethods
  }

  const loadUserNfts = async (address: string): Promise<SheltersNft[]> => {
    const nftIdentifiers = await PropertyService.getNftIdentifiers()
    const userCollections = await UserService.getNfts(address, nftIdentifiers)
    dispatch(setNfts(userCollections))
    return userCollections
  }

  const loadNextPayouts = async (
    nfts: SheltersNft[],
  ): Promise<NextPayout[]> => {
    const userNextPayouts: NextPayout[] = await UserService.getNextYields(nfts)
    dispatch(setYields(userNextPayouts))
    return userNextPayouts
  }

  return (
    <authContext.Provider
      value={{
        login,
        authWithGoogle,
        authWithGoogleCallback,
        logout,
        isLoggedIn,
        user,
        setUser: _setUser,
        isLoading,
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(authContext)
