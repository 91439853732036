import api from './api'
import {
  Property,
  GetPropertiesResult,
  GetPropertyDocumentsResponse,
  PropertyNew,
} from '../types/Property/Property'

const PropertyService = {
  async getProperties({
    page,
    pageSize,
    lang,
    order,
    orderBy,
    filter,
    filterBy,
    filterOperator,
    filterCondition,
    keyword,
  }: {
    page?: number
    pageSize?: number
    lang?: string
    order?: string
    orderBy?: string
    filter?: string[]
    filterBy?: string[]
    filterOperator?: string[]
    filterCondition?: string
    keyword?: string
  }): Promise<GetPropertiesResult> {
    const { data } = await api.get('/v1/properties', {
      params: {
        page,
        pageSize: pageSize ? pageSize : 10,
        lang: lang ?? 'en',
        order: order ?? 'DESC',
        orderBy: orderBy ?? 'createdAt',
        filter: filter ?? [],
        filterBy: filterBy ?? [],
        filterOperator: filterOperator ?? [],
        filterCondition: filterCondition ?? '',
        keyword,
      },
    })
    return data
  },

  async getProperty({
    id,
    lang,
  }: {
    id: string
    lang?: string
  }): Promise<Property> {
    const { data } = await api.get(`/v1/properties/${id}`, {
      params: {
        lang: lang ?? 'en',
      },
    })

    return data
  },

  async getFeaturedProperties(): Promise<Property> {
    //TODO add real getFeaturedProperty route
    const { data } = await api.get('/v1/properties')
    return data.properties[0]
  },

  async getPropertyDocuments(
    id: string,
    language: string,
  ): Promise<GetPropertyDocumentsResponse> {
    const { data } = await api.get(`/v1/properties/${id}/documents`, {
      params: {
        lang: language,
      },
    })
    return data
  },

  async getNftIdentifiers(): Promise<string[]> {
    const { data } = await api.get('/v1/properties/nft-identifiers')

    return data.collections
  },

  async getContributorsCount(id: string): Promise<{ count: number }> {
    const { data } = await api.get(`/v1/properties/${id}/contributors`)
    return data
  },

  async getNews(propertyId: string): Promise<PropertyNew[]> {
    const { data } = await api.get(`/v1/properties/${propertyId}/news`)
    return data
  },
}

export default PropertyService
