import { PayoutHistory } from './User'

export interface getUserPayoutMethodsResponse {
  userPayoutMethods: UserPayoutMethod[]
  pagination: any
}

export interface ResponseGetPayoutsHistory {
  payoutsHistory: PayoutHistory[]
}

export interface UserPayoutMethod {
  id: string
  userId: string
  payoutMethod: PayoutMethod
  userPayoutMethodMultiversx: UserPayoutMethodMultiversx | null
  userPayoutMethodPaypal: UserPayoutMethodPaypal | null
  userPayoutMethodRevolut: UserPayoutMethodRevolut | null
  active: boolean
  isCurrent: boolean
  isNext: boolean
  createdAt: Date
  updatedAt: Date
}

export interface PayoutMethod {
  id: number
  name: PayoutMethodName
  type: PayoutMethodType
  currency: PayoutCurrency
  feesFixed: number
  feesPercent: number
  active: boolean
}

export interface UserPayoutMethodMultiversx {
  id: string
  address: string
}

export interface UserPayoutMethodPaypal {
  id: string
  email: string
  paypalPayerId: string
}

export interface UserPayoutMethodRevolut {
  id: string
  revolutCounterpartyId: string
}

export enum PayoutMethodName {
  REVOLUT = 'revolut',
  PAYPAL = 'paypal',
  MULTIVERSX = 'multiversx',
}

export enum PayoutMethodType {
  REVOLUT_EUR = 'revolut_eur',
  PAYPAL_EUR = 'paypal_eur',
  MULTIVERSX_USDC = 'multiversx_usdc',
}

export enum PayoutFiatCurrency {
  EUR = 'EUR',
}

export enum PayoutCryptoCurrency {
  USDC = 'USDC',
}

export type PayoutCurrency = PayoutCryptoCurrency | PayoutFiatCurrency

export enum PayoutDistributionTransactionErrorFlags {
  USER_NOT_FOUND = 'user_not_found',
  USER_NOT_VERIFIED = 'user_not_verified',
  USER_PAYOUT_METHOD_NOT_FOUND = 'user_payout_method_not_found',
  FEES_TOO_HIGH = 'fees_too_high',
  PROPERTY_PAYOUT_METHOD_NOT_FOUND = 'property_payout_method_not_found',
  PROPERTY_MISSING_FUNDS = 'property_missing_funds',
  DISTRIBUTION_ERROR = 'distribution_error',
}
