import { CurrencyEnum } from '../Currency'
import { UserWallet } from '../User'

export interface CreateOrderResult {
  orderId: string
  redirectUrl: string
}

export interface GetOrdersResult {
  orders: Order[]
  page: number
  pageSize: number
  maxPages: number
  maxResults: number
}

export interface Order {
  id: string
  type: OrderType
  paymentMethod: OrderPaymentMethod
  status: OrderStatus
  paymentStatus: OrderPaymentStatus
  txStatus: OrderTxStatus
  txHash: string
  externalOrderId: string
  amount: number
  currency: CurrencyEnum
  nftNonce: string
  nftQuantity: number
  addressStreet: string
  addressCity: string
  addressZipcode: string
  addressCountry: string
  propertyId: string
  userWallet?: UserWallet
  createdAt: string
}

export enum OrderPaymentMethod {
  XMONEY_CRYPTO = 'xmoney_crypto',
  STRIPE = 'stripe',
}

export enum OrderType {
  ORIGINALS = 'originals',
}

export enum OrderStatus {
  ONGOING = 'ongoing',
  SUCCESS = 'success',
  CANCELLED = 'cancelled',
}

export enum OrderPaymentStatus {
  AWAITING = 'awaiting',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export enum OrderTxStatus {
  PROCESSING = 'processing',
  ERROR = 'error',
  SUCCESS = 'success',
}
