import '../styles/globals.css'
import '../styles/embla.css'
import React, { useEffect } from 'react'
import { Metadata, NextComponentType, Viewport } from 'next'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import AuthProvider from '../providers/AuthProvider'
import AxiosInterceptor from '../providers/AxiosInterceptor'
import MultiversxProvider from '../providers/MultiversxProvider'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import { getCanonicalPath, getFullPath } from '../lib/utils/language'
import i18nextConfig from '../../next-i18next.config'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import { NotificationProvider } from '../providers/NotificationProvider'
import propertyService from '../services/property.service'
import { setProperties, useDispatch, ReduxProvider } from '../store'
import MultiversxInterceptor from '../providers/MultiversxInterceptor'
import useLocale from '../hooks/useLocale'
import { FlagProvider } from '@unleash/nextjs/client'
const LocalizedFormat = require('dayjs/plugin/localizedFormat')
const calendar = require('dayjs/plugin/calendar')
require('dayjs/locale/fr')
dayjs.extend(LocalizedFormat)
dayjs.extend(calendar)

export const metadata: Metadata = {
  appleWebApp: true,
}

export const viewport: Viewport = {
  width: 'device-width minimal-ui',
  initialScale: 1,
  maximumScale: 1,
  userScalable: false,
}

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['staging', 'production'],
    releaseStage: process.env.NEXT_PUBLIC_TARGET_ENV,
  })
}

function BaseLayout() {
  const dispatch = useDispatch()
  const { locale } = useLocale()

  const loadProperties = async () => {
    const { properties } = await propertyService.getProperties({
      order: 'DESC',
      lang: locale,
    })
    dispatch(setProperties(properties))
  }

  useEffect(() => {
    loadProperties()
    dayjs.locale(locale)
  }, [locale])

  return null
}

const App: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps,
}: AppLayoutProps) => {
  const router = useRouter()
  const { locale } = useLocale()
  const otherLocales = i18nextConfig.i18n.locales.filter(
    (l: string) => l !== locale,
  )
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ReduxProvider>
      <FlagProvider
        config={{
          refreshInterval: 60,
          environment: 'production',
          // see https://github.com/Unleash/unleash-proxy-client-js#available-options
        }}
      >
        <AuthProvider>
          <AxiosInterceptor>
            <NotificationProvider>
              <MultiversxProvider>
                <MultiversxInterceptor>
                  {GTM_ID && typeof window !== 'undefined' && (
                    <Script
                      id="google-tag-manager"
                      strategy="afterInteractive"
                      dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');`,
                      }}
                    ></Script>
                  )}
                  {GTM_ID && (
                    <noscript
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                      }}
                    ></noscript>
                  )}
                  <DefaultSeo
                    canonical={getCanonicalPath(
                      getFullPath(router.asPath, locale),
                    )}
                    openGraph={{
                      type: 'website',
                      locale: locale,
                      url: `${getFullPath(router.asPath, locale)}`,
                      siteName: 'Shelters',
                      images: [
                        {
                          url: `${process.env.NEXT_PUBLIC_WWW_BASEURL}/img/logos/shelters/logo-full-x1700.png`,
                          width: 1700,
                          height: 899,
                          alt: 'Shelters logo',
                        },
                      ],
                    }}
                    languageAlternates={otherLocales
                      .map((locale: string) => ({
                        hrefLang: locale,
                        href: `${getFullPath(router.asPath, locale)}`,
                      }))
                      .concat({
                        hrefLang: 'x-default',
                        href: `${getFullPath(
                          router.asPath,
                          process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
                        )}`,
                      })}
                    twitter={{
                      site: '@' + process.env.NEXT_PUBLIC_TWITTER_ACCOUNT,
                      cardType: 'summary_large_image',
                    }}
                    facebook={{
                      appId: process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID,
                    }}
                    themeColor="rgba(51,40,154,1)"
                    additionalLinkTags={[
                      {
                        rel: 'icon',
                        href: '/img/logos/shelters/logo-32x32-min.png',
                        sizes: '32x32',
                      },
                      {
                        rel: 'icon',
                        href: '/img/logos/shelters/logo-192x192-min.png',
                        sizes: '192x192',
                      },
                      {
                        rel: 'apple-touch-icon',
                        href: '/img/logos/shelters/logo-180x180-min.png',
                        sizes: '180x180',
                      },
                      {
                        rel: 'manifest',
                        href: `/manifest-${locale}.json`,
                      },
                    ]}
                    additionalMetaTags={[
                      {
                        name: 'viewport',
                        content:
                          'width=device-width, minimum-scale=1, initial-scale=1, viewport-fit=cover',
                      },
                      {
                        name: 'trustpilot-one-time-domain-verification-id',
                        content: '744162a1-4dd0-4404-8aa8-30d23c409e77',
                      },
                    ]}
                  />
                  <BaseLayout />
                  {getLayout(<Component {...pageProps} />)}
                </MultiversxInterceptor>
              </MultiversxProvider>
            </NotificationProvider>
          </AxiosInterceptor>
        </AuthProvider>
      </FlagProvider>
    </ReduxProvider>
  )
}

export default appWithTranslation(App)
